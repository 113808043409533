import React from 'react';
import PageBanner from '../components/Common/PageBanner';
import { Link } from 'react-router-dom';

const Integration = () => {
    
  return (
    <div>
        <PageBanner pageTitle="Integartion" />

        <div className='features-details-area ptb-80'>
            <div className='container'>
                <div className="row">
                    <div className="col-lg-6">
                        <img
                            src="/images/integration-img/indiamart.png"
                            className="animate__animated animate__fadeInUp"
                            alt="image"
                        />
                    </div>
                    <div className="col-lg-6">
                        <div className="features-details-desc">
                            <h2>Integrate Indiamart with BeTyphon</h2><br/>
                            <p className='fs-6'>We are offering auto-integration with Indiamart thru secure process of integration. Request you to follow the mention below points while integration.<br/><br/>

                            From Indiamart, We required CRM KEY (Setting under the Indiamart Login Panel) and registerred Mobile Number to integrate with each-other (BeTyphon and Indiamart).</p>
                        </div><br/>
                        <Link to="https://betyphon.tawk.help/article/how-to-integrate-betyphon-with-indiamart" className='btn btn-primary' target="_blank">Know more</Link>
                    </div>
                </div>
            </div>

            <div className="separate"></div>
            
            <div className='container'>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="features-details-desc">
                            <h2>Integrate Whatsapp Cloud API with BeTyphon</h2><br/>
                            <p className='fs-6'>By Setting-up Whatsapp API with BeTyphon, it helps more interraction to make the conversation more rich...<br/><br/>
                            1. Before proceeding Whatsapp Clound API, We suppose to understand the difference between Whatsapp Official API and Whatsapp Cloud API. For reference - https://whatsappapi.tawk.help/article/whats-the-difference-between-whatsapp-business-api-and-betyphon-whatsapp-api<br/><br/>
                            </p>
                        </div><br/>
                        <Link to="https://betyphon.tawk.help/article/how-to-integrate-whatsapp-cloud-api-with-betyphon" className='btn btn-primary'target="_blank">Know more</Link>
                    </div>
                    <div className="col-lg-6">
                        <img
                            src="/images/integration-img/whatsapp_cloud.png"
                            className="animate__animated animate__fadeInUp"
                            alt="image"
                        />
                    </div>
                </div>
            </div>

            <div className="separate"></div>

            <div className='container'>
                <div className="row">
                    <div className="col-lg-6">
                        <img
                            src="/images/features-details-image/feature2.png"
                            className="animate__animated animate__fadeInUp"
                            alt="image"
                        />
                    </div>
                    <div className="col-lg-6">
                        <div className="features-details-desc">
                            <h2>Integrate with Facebook</h2><br/>
                            <p className='fs-6'>You can integrate all 4 mediums of Social Media leads<br/><br/>
                                - Whatsapp Leads can be integrated with Whatsapp API Keyword Feature in CRM.<br/><br/>
                                - Incoming Call can be integrated with IVR Services in CRM<br/><br/>
                                - Facebook Instant Form, can be integrated directly with Marketplace integration</p>
                        </div><br/>
                        <Link to="https://betyphon.tawk.help/article/how-to-integrate-betyphon-with-facebook" className='btn btn-primary' target="_blank">Know more</Link>
                    </div>
                </div>
            </div>

            <div className="separate"></div>
            
            <div className='container'>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="features-details-desc">
                            <h2>Integrate Whatsapp API with Betyphon</h2><br/>
                            <p className='fs-6'>Whatsapp integration is very easy with Betyphon - Mobile CRM...  You can integrate any Whatsapp API from anywhere. Although BeTyphon Whatsapp API is also available with very basic cost as transaction without any setup or monthly charges.<br/><br/>
                            Whatsapp API is generally - 5 type's and all can be integrated based on need. Below is details as the integration process step-by-step and how these message seems after transaction.</p>
                        </div><br/>
                        <Link to="https://betyphon.tawk.help/article/whatsapp-api-integration" className='btn btn-primary' target="_blank">Know more</Link>
                    </div>
                    <div className="col-lg-6">
                        <img
                            src="/images/features-details-image/feature2.png"
                            className="animate__animated animate__fadeInUp"
                            alt="image"
                        />
                    </div>
                </div>
            </div>

            <div className="separate"></div>

            <div className='container'>
                <div className="row">
                    <div className="col-lg-6">
                        <img
                            src="/images/features-details-image/feature2.png"
                            className="animate__animated animate__fadeInUp"
                            alt="image"
                        />
                    </div>
                    <div className="col-lg-6">
                        <div className="features-details-desc">
                            <h3>Integrate with Facebook</h3>
                            <p>Introducing our all-encompassing solution – the "One Dashboard for All," seamlessly integrating the elements of Enhancing Caller Efficiency, Task Automation through Integrations, and establishing an Effortless Operations Hub. This <br/>comprehensive dashboard serves as the centralized nerve center for optimizing caller interactions and overall operational efficiency. By identifying opportunities to improve caller productivity, we empower businesses to harness real-time calling data without making assumptions. The Task Automation through Integrations simplifies repetitive tasks, such as updating contacts and managing leads, creating a streamlined workflow. The Effortless Operations Hub consolidates these functionalities, offering a unified and user-friendly platform for businesses to enhance their productivity, ensure smoother operations, and make informed decisions effortlessly. With this one-stop dashboard, businesses can transform their operations into a synchronized and efficient powerhouse.</p>
                        </div><br/>
                        <button className='btn btn-primary'>Know more</button>
                    </div>
                </div>
            </div>

            <div className="separate"></div>
            
            <div className='container'>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="features-details-desc">
                            <h3>Integrate with Facebook</h3>
                            <p>Introducing our all-encompassing solution – the "One Dashboard for All," seamlessly integrating the elements of Enhancing Caller Efficiency, Task Automation through Integrations, and establishing an Effortless Operations Hub. This comprehensive dashboard serves as the centralized nerve center for optimizing caller interactions and overall operational efficiency. By identifying opportunities to improve caller productivity, we empower businesses to harness real-time calling data without making assumptions. The Task Automation through Integrations simplifies repetitive tasks, such as updating contacts and managing leads, creating a streamlined workflow. The Effortless Operations Hub consolidates these functionalities, offering a unified and user-friendly platform for businesses to enhance their productivity, ensure smoother operations, and make informed decisions effortlessly. With this one-stop dashboard, businesses can transform their operations into a synchronized and efficient powerhouse.</p>
                        </div><br/>
                        <button className='btn btn-primary'>Know more</button>
                    </div>
                    <div className="col-lg-6">
                        <img
                            src="/images/features-details-image/feature2.png"
                            className="animate__animated animate__fadeInUp"
                            alt="image"
                        />
                    </div>
                </div>
            </div>

            <div className="separate"></div>            
        </div>
    
    </div>
  )
}

export default Integration