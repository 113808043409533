import React from 'react';
import * as Icon from 'react-feather';
// import './Pop-up.css'
import { useState } from 'react';
import CalendlyPage from './CalendlyComponent';

const PopUp = ({ handleClose, show }) => {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';

  // const [calendlyVisible, setCalendlyVisible] = useState(false);

  // const handleButtonClick = () => {
  //   setCalendlyVisible(true);
  // };
  const [schedulePopUp, setSchedulePopUp] = useState(false);

  const show_schedule = () =>{
    setSchedulePopUp(true);
  }

  return (
    <div id="popup-container" style={{display: 'block'}}>
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 p-5">
              <p className="fs-2">Get Started With a <strong>Personalized Demo </strong></p>
              <p className="text-secondary">Book a Demo with one of our experts and we will show you how Betyphon can streamline your internal workflow.</p>
              <button onClick={show_schedule} className="btn btn-dark rounded-2 position-relative py-2 px-3 my-4">Schedule Now</button>
          </div>
          
          <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
              <div className="text-end p-2">
                <Icon.X onClick={handleClose} style={{cursor: 'pointer'}}/>
              </div>
              <img src="https://cdni.iconscout.com/illustration/premium/thumb/presenter-showing-business-stats-to-employees-1917438-1633456.png" className="mx-auto d-block w-100"/>
          </div>
        </div>
      </section>
      {schedulePopUp && <CalendlyPage handleClose={setSchedulePopUp} />}
    </div>
    </div>
  );
};

export default PopUp;
