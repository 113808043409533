import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';
import './MainBanner.css';
import Modal from 'react-modal';
import Owl_Carousel from './Owl_Carousel';

Modal.setAppElement('#root');

const MainBanner = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      <div className="main-banner">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="row h-100 justify-content-center align-items-center" data-aos="fade-up">
                <div className="col-lg-5">
                  <div className="hero-content">
                    <h1><b>Best Telecalling CRM with lead integrations & Auto Dialer</b></h1>
                    <p className="fs-6">
                      Capture leads from anywhere and distribute in your team members, Track your calling activity and improve the productivity with proper feedback. Your caller just needs a Smart-Phone with a SimCard in your phone.
                    </p>

                    <Link to="https://web.betyphon.in/auth/register-page" className="btn btn-primary m-1">
                      Register With Us
                    </Link>
                    <Link to="https://www.youtube.com/watch?v=E81XyemCeVY&t=1s" className="btn btn-primary">
                      Watch Demo Video
                    </Link>
                  </div>
                  <div className="row owl_carousel">
                    <p className="fs-6 mt-3"><b>Trusted by 5000+ people from</b></p>
                    <Owl_Carousel/>
                  </div>
                </div>

                <div className="col-lg-6 offset-lg-1">
                  <div className="banner-image" data-aos="fade-up">
                    <div className="video-background">
                      <video autoPlay loop muted className=' bg-light p-0 rounded-1'>
                        <source
                          src="/images/crm_vedio.mp4"
                          type="video/mp4"
                        />
                      </video>
                    </div>
                    <div className="button-overlay">
                      <button
                        onClick={openModal}
                        className="watch-now-btn"
                      >
                        Let's Understand
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="YouTube Video"
        style={{
          overlay: {
            zIndex: 1000,
            marginTop: '5%',
            width: '100%',
          },
          content: {
            zIndex: 1001,
            background: 'transparent',
            border: 'none',
          },
        }}
      >
        <div className="modal-content-zoomIn">
          <button
            onClick={closeModal}
            className="btn btn-primary close-btn"
          >
            Close
          </button>
          <iframe
            src="https://www.youtube.com/embed/o7lWcdcyo4w"
            title="YouTube Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="you-tube"
          ></iframe>
        </div>
      </Modal>
    </>
  );
};

export default MainBanner;
