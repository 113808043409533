import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
import withReactContent from 'sweetalert2-react-content/dist/sweetalert2-react-content.es.js';

const MySwal = withReactContent(Swal);
// import baseUrl from '../../utils/baseUrl'

const alertContent = () => {
    MySwal.fire({
      title: "Congratulations!",
      text: "Your message was successfully send and will back to you soon",
      icon: "success",
      timer: 2000,
      timerProgressBar: true,
      showConfirmButton: false,
    });
  };

const ContactForm = () => {
    const [contact, setContact] = useState({
        name: "",
        email: "",
        number: "",
        event_date: "",
        occasion: "",
        people: "",
        text: "",
      });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setContact((prevState) => ({ ...prevState, [name]: value }));
        // console.log(contact)
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const url = "https://web.betyphon.in/api/email/sendEmailAPI";
            // const url = `${baseUrl}/api/contact`;
            // const { name, email, number, event_date, occasion, people, text } = contact;
            // const message = { name, email, number, event_date, occasion, people, text };
            // let html = message + ' for email: ' + formValues.email;
            // let html = message + ' for email: '

            
            var mailOptions = {
                "querydata": {
                "externalUse": true,
                "configName": "email",
                "admin": "info@techmet.in"
                },
                // email:formValues.email, 
                // from: "betyphon.crm@gmail.com",
                // to: "rishabhchaurasia456@gmail.com",
                subject: "BeTyphon - New enquiry",
                mailbody: `
                <b>Dear Betyphon</b>
                <p>Hello You have New enquiry from your website.</p>
                <table border="1">
                  <tr><td>Name:</td><td>${contact.name}</td></tr>
                  <tr><td>Email:</td><td>${contact.email}</td></tr>
                  <tr><td>Phone Number:</td><td>${contact.number}</td></tr>
                  <tr><td>Event Date:</td><td>${contact.event_date}</td></tr>
                  <tr><td>Occasion:</td><td>${contact.occasion}</td></tr>
                  <tr><td>Number of People:</td><td>${contact.people}</td></tr>
                  <tr><td>Message:</td><td>${contact.text}</td></tr>
                </table>
              `,
                type:["email"]
            };
            await axios.post(url, mailOptions)
            .then(response => {
                console.log("Successfully sending email:", response);
              })
              .catch(error => {
                console.error("Error sending email:", error);
              });
            setContact({
                name: "",
                email: "",
                number: "",
                event_date: "",
                occasion: "",
                people: "",
                text: "",
            });
            alertContent();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className="contact-area ptb-80">
                <div className="container">
                    <div className="section-title">
                        <h2>Get In Touch With Us</h2>
                        <div className="bar"></div>
                        <p>Anything On your Mind. We’ll Be Glad To Assist You!</p>
                    </div>

                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <img src="/images/contact-img.png" alt="image" />
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input 
                                                type="text" 
                                                name="name" 
                                                placeholder="Your Name" 
                                                className="form-control" 
                                                value={contact.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input 
                                                type="email" 
                                                name="email" 
                                                placeholder="Email ID" 
                                                className="form-control" 
                                                value={contact.email}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <input 
                                                type="text" 
                                                name="number" 
                                                placeholder="Your phone number" 
                                                className="form-control" 
                                                value={contact.number}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <input 
                                                type="date" 
                                                name="event_date" 
                                                placeholder="Date-of-Occasion"
                                                className="form-control" 
                                                value={contact.event_date}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <input 
                                                type="text" 
                                                name="occasion" 
                                                placeholder="Type of Occasion" 
                                                className="form-control" 
                                                value={contact.occasion}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <input 
                                                type="number" 
                                                name="people" 
                                                placeholder="Number of People" 
                                                className="form-control" 
                                                value={contact.people}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea 
                                                name="text" 
                                                cols="30" 
                                                rows="5" 
                                                placeholder="Write your message..." 
                                                className="form-control" 
                                                value={contact.text}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>

                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                By checking this, you agree to our <Link href="/term-condition">Terms</Link> and <Link href="/privacy-policy">Privacy policy</Link>.
                                            </label>
                                        </div>
                                    </div>
                
                                    <div className="col-lg-12 col-sm-12">
                                        <button type="submit" className="btn btn-primary">Send Message</button>
                                    </div>
                                </div>
                            </form> 
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactForm;  