import React, { useEffect, useState } from 'react';
import * as Icon from 'react-feather';
// import "./ServicesArea.css"

const ServicesArea = () => {

    const [imageSrc, setImageSrc] = useState('/images/crmperview/dashboard.jpg');

    useEffect(() => {
        const p = document.querySelectorAll('.bgnClickChange');

        const pData = [
            { imageSrc: ('/images/crmperview/dashboard.jpg') },
            { imageSrc: ('/images/crmperview/My team.jpg') },
            { imageSrc: ('/images/crmperview/campaigns.jpg') },
            { imageSrc: ('/images/crmperview/activity.jpg') },
            { imageSrc: ('/images/crmperview/report.jpg') },
            { imageSrc: ('/images/crmperview/schedule.jpg') },
            { imageSrc: ('/images/crmperview/prospects.jpg') },
            { imageSrc: ('/images/crmperview/forms.jpg') },
            { imageSrc: ('/images/crmperview/system log.jpg') },
            { imageSrc: ('/images/crmperview/integration.jpg') },
            { imageSrc: ('/images/crmperview/template.jpg') },
        ];

        p.forEach((p, index) => {
            p.addEventListener('click', () => {
                const data = pData[index];
                setImageSrc(data.imageSrc);
            });
        });
    }, []);

    // document.cookie = "connect.sid=s%253AUf3XqzBI28Gd8tFOtfG5wIXHg1lTx1UM; SameSite=Lax; Secure; domain=https://web.betyphon.in; path=/";

    return (
        <>
            <div className="services-area ptb-80 bg-f7fafd">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 services-content">
                            <div className="section-title">
                                <h2>The #1 Lead Management & Telecalling Mobile Dialing CRM</h2>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="box">
                                        <Icon.Check /> Premium support for zero downtime
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="box">
                                        <Icon.Check /> Track your team from anywhere - SAAS
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="box">
                                        <Icon.Check /> Auto Dial and Manage leads from Phone.
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="box">
                                        <Icon.Check /> Auto-distribute leads across your Team.
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="box">
                                        <Icon.Check /> Pay-per-Go SAAS Model
                                    </div>
                                </div>

                                
                                
                                <div className="col-lg-12 col-md-12 mt-3">
                                    <h2>Lets upgrade the productivity and supercharge sales.</h2>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 services-right-image">
                            <iframe width="100%" className='youtube_iframe' src="https://www.youtube.com/embed/E81XyemCeVY?si=Wx9IhBgLKiDLrccn&amp;start=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                        </div>
                    </div>
                </div>
            </div>
            
            <div className="crm_preview">
                <div className="services-area ptb-80">
                        <div className="section-title">
                            <h2 className='text-center'>BeTyphon CRM Perview</h2>
                                <div className="bar"></div>
                            <h5 className='text-center'>Click on each menu to change Dashboard perview</h5>
                        </div>
                    <div className="container">
                        <div className="cm-dashboard py-5">
                            <div className="container-fluid">
                                <img src={imageSrc} className="mx-auto d-block rounded w-100 shadow-lg" id="changeDashboard" />
                                <div className="row gx-0">
                                    <div className="col-0 col-sm-0 col-md-0 col-lg-2 col-xl-2 col-xxl-2">
                                        <ul className="bgnClick position-absolute" style={{ paddingLeft: "3%", width: "81.5%", marginTop: "-44.3%",}} id="">
                                            <li className='list_style'>
                                                <p id="d1" className="listItemStyle text-white bgnClickChange pt-2"></p>
                                            </li>
                                            <li className='list_style'>
                                                <p id="d2" className="listItemStyle text-white bgnClickChange pt-2"></p>
                                            </li>
                                            <li className='list_style'>
                                                <p id="d3" className="listItemStyle text-white bgnClickChange pt-2"></p>
                                            </li>
                                            <li className='list_style'>
                                                <p id="d4" className="listItemStyle text-white bgnClickChange pt-2"></p>
                                            </li>
                                            <li className='list_style'>
                                                <p id="d5" className="listItemStyle text-white bgnClickChange pt-2"></p>
                                            </li>
                                            <li className='list_style'>
                                                <p id="d6" className="listItemStyle text-white bgnClickChange pt-2"></p>
                                            </li>
                                            <li className='list_style'>
                                                <p id="d7" className="listItemStyle text-white bgnClickChange pt-2"></p>
                                            </li>
                                            <li className='list_style'>
                                                <p id="d8" className="listItemStyle text-white bgnClickChange pt-2"></p>
                                            </li>
                                            <li className='list_style'>
                                                <p id="d9" className="listItemStyle text-white bgnClickChange pt-2"></p>
                                            </li>
                                            <li className='list_style'>
                                                <p id="d10" className="listItemStyle text-white bgnClickChange pt-2"></p>
                                            </li>
                                            <li className='list_style'>
                                                <p id="d11" className="listItemStyle text-white bgnClickChange pt-2"></p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-0 col-sm-0 col-md-0 col-lg-10 col-xl-10 col-xxl-10">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div>
                        <iframe title="CRM Preview" src="https://web.betyphon.in/admin" frameBorder="0" width="100%" height="800px" ></iframe>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default ServicesArea;