import React from 'react'

const Privacy_policy = () => {
  return (
    <div>
    <div className='container mt-5 pt-5'><b>General Information</b><br/><br/>
    We collect the e-mail addresses of those who communicate with us via e-mail, aggregate information on what pages consumers access or visit, and information volunteered by the consumer (such as survey information and/or site registrations). The information we collect is used to improve the content of our Web pages and the quality of our service, and is not shared with or sold to other organizations for commercial purposes, except to provide products or services you’ve requested, when we have your permission, or under the following circumstances:<br/><br/>
    
    - It is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of Terms of Service, or as otherwise required by law.<br/>
    - We transfer information about you if BeTyphon is acquired by or merged with another company. In this event, BeTyphon will notify you before information about you is transferred and becomes subject to a different privacy policy.<br/><br/>
    
    <b>Information Gathering and Usage</b><br/><br/>
    - When you register for BeTyphon we ask for information such as your name, email address, billing address, credit card information. Members who sign up for the free account are not required to enter a credit card.<br/>
    - BeTyphon uses collected information for the following general purposes: products and services provision, billing, identification and authentication, services improvement, contact, and research.<br/><br/>
    
    <b>Cookies</b><br/><br/>
    A cookie is a small amount of data, which often includes an anonymous unique identifier, that is sent to your browser from a web site’s computers and stored on your computer’s hard drive.<br/>
    Cookies are required to use the BeTyphon service.<br/>
    We use cookies to record current session information, but do not use permanent cookies. You are required to re-login to your BeTyphon account after a certain period of time has elapsed to protect you against others accidentally accessing your account contents.<br/><br/>
    
    <b>Data Storage</b><br/><br/>
    BeTyphon uses third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run BeTyphon. Although BeTyphon owns the code, databases, and all rights to the BeTyphon application, you retain all rights to your data. We agree to abide by and maintain adequate data security measures, consistent with industry standards and technology best practices, to protect your Data from unauthorized disclosure or acquisition by an unauthorized person and is protected by HTTPS SSL and Firewall. We intend to appraise that the currently application is running on AWS EC2 instances. Your data is stored in MongoDB also on AWS EC2 instances. There is an added layer of data security through these vendors in addition to our own data security layer.<br/><br/>
    
    
    <b>Disclosure</b><br/><br/>
    BeTyphon may disclose personally identifiable information under special circumstances, such as to comply with subpoenas or when your actions violate the Terms of Service.<br/><br/>
    
    
    <b>Changes</b><br/><br/>
    BeTyphon may periodically update this policy. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your BeTyphon primary account holder account or by placing a prominent notice on our site. Such notice will be given at least 3 days in advance of the date the new policy will be applied.<br/><br/>
    
    
    <b>Acess</b><br/><br/>
    BeTyphon access all Call logs such as incoming, outgoing & missed calls and File Manager for Recording file as its related to prospect in CRM which will be displayed in Betyphon. Location is also get access for field tracking purpose .<br/><br/>
    
    
    <b>Question</b><br/><br/>
    Any questions about this Privacy Policy should be addressed to support@betyphon.com or 1800-120-6389.<br/>
    </div>
    </div>
  )
}

export default Privacy_policy