import React, { useState, useEffect, useRef } from 'react';
import PageBanner from '../components/Common/PageBanner';
import * as Icon from 'react-feather';

const FeatureDetails = () => {
    return (
        <>
            <PageBanner pageTitle="Feature Details" />

            <div className="features-details-area ptb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 features-details-image">
                            <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <img src="/images/features-details-image/feature1.png" class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="/images/features-details-image/feature2.png" class="d-block w-100" alt="..." />
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                                    {/* <span class="carousel-control-prev-icon" aria-hidden="true"></span> */}
                                    <Icon.ChevronLeft className='text-dark' />
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                                    {/* <span class="carousel-control-next-icon" aria-hidden="true"></span> */}
                                    <Icon.ChevronRight className='text-dark' />
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>

                        <div className="col-lg-6 features-details">
                            <div className="features-details-desc">
                                <h3>Dashboard For All</h3>
                                <p className='fs-6'><Icon.CheckCircle className='text-primary'/>&nbsp; &nbsp;Graphical representation of data for quick and efficient information display.</p>
                                <p className='fs-6'><Icon.CheckCircle className='text-primary'/>&nbsp; &nbsp;Advanced filtering options for in-depth business insights.</p>
                                <p className='fs-6'><Icon.CheckCircle className='text-primary'/>&nbsp; &nbsp;Real-time call analytics for total, incoming, outgoing, and missed calls.</p>
                                <p className='fs-6'><Icon.CheckCircle className='text-primary'/>&nbsp; &nbsp;Individual user progress tracking with personalized feedback for improvement.</p>
                            </div>
                        </div>
                    </div>

                    <div className="separate"></div>

                    <div className="row align-items-center">
                        <div className="col-lg-6 features-details">
                            <div className="features-details-desc">
                                <h3>Dynamic calling process</h3>
                                <p className='fs-6'><Icon.CheckCircle className='text-primary'/>&nbsp; &nbsp;Automate your entire workflow with dispostion-based rule.</p>
                                <p className='fs-6'><Icon.CheckCircle className='text-primary'/>&nbsp; &nbsp;Implement seamless chain automation to effortlessly transfer contacts between one campagin to another campagin.</p>
                                <p className='fs-6'><Icon.CheckCircle className='text-primary'/>&nbsp; &nbsp;Cycle-based calling feature allow the systeamtic and efficiently manage the customer interactions without wasting time.</p>
                                <p className='fs-6'><Icon.CheckCircle className='text-primary'/>&nbsp; &nbsp;Utilize live analytics to constantly refine and improve call processes.</p>
                            </div>
                        </div>

                        <div className="col-lg-6 features-details-image">
                            <div id="carouselExampleAutoplaying1" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <img src="/images/features-details-image/feature1.png" class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="/images/features-details-image/feature2.png" class="d-block w-100" alt="..." />
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying1" data-bs-slide="prev">
                                    {/* <span class="carousel-control-prev-icon" aria-hidden="true"></span> */}
                                    <Icon.ChevronLeft className='text-dark' />
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying1" data-bs-slide="next">
                                    {/* <span class="carousel-control-next-icon" aria-hidden="true"></span> */}
                                    <Icon.ChevronRight className='text-dark' />
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 features-details-image">
                            <div id="carouselExampleAutoplaying2" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <img src="/images/features-details-image/feature1.png" class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="/images/features-details-image/feature2.png" class="d-block w-100" alt="..." />
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying2" data-bs-slide="prev">
                                    {/* <span class="carousel-control-prev-icon" aria-hidden="true"></span> */}
                                    <Icon.ChevronLeft className='text-dark' />
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying2" data-bs-slide="next">
                                    {/* <span class="carousel-control-next-icon" aria-hidden="true"></span> */}
                                    <Icon.ChevronRight className='text-dark' />
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>

                        <div className="col-lg-6 features-details">
                            <div className="features-details-desc">
                                <h3>Dynamic Lead entries</h3>
                                <p className='fs-6'><Icon.CheckCircle className='text-primary'/>&nbsp; &nbsp;Effortlessly capture leads from diverse sources through automated processes.</p>
                                <p className='fs-6'><Icon.CheckCircle className='text-primary'/>&nbsp; &nbsp;Facebook, Email, Magicbricks, Tradeindia, Sulekha, 99 acres, Collage dunia & 30+ sources.</p>
                                <p className='fs-6'><Icon.CheckCircle className='text-primary'/>&nbsp; &nbsp;Integrate your favourite tools to get the enquiry on real-time.</p>
                                <p className='fs-6'><Icon.CheckCircle className='text-primary'/>&nbsp; &nbsp;Seamlessly import extensive lead data from Excel with a single, user-friendly click.</p>
                            </div>
                        </div>
                    </div>

                    <div className="separate"></div>

                    <div className="row align-items-center">
                        <div className="col-lg-6 features-details">
                            <div className="features-details-desc">
                                <h3>Easy Communication - Whatsapp, SMS  and Email</h3>
                                <p className='fs-6'><Icon.CheckCircle className='text-primary'/>&nbsp; &nbsp;Facilitate seamless communication with leads through the intuitive features of our CRM system.</p>
                                <p className='fs-6'><Icon.CheckCircle className='text-primary'/>&nbsp; &nbsp;Consolidate SMS, WhatsApp, and email communication in a unified platform, ensuring clarity and seamless interaction without any ambiguity.</p>
                                <p className='fs-6'><Icon.CheckCircle className='text-primary'/>&nbsp; &nbsp;Efficiently convey comprehensive business proposals, providing quick and informative communication for streamlined decision-making.</p>
                            </div>
                        </div>

                        <div className="col-lg-6 features-details-image">
                            <div id="carouselExampleAutoplaying3" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <img src="/images/features-details-image/feature1.png" class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="/images/features-details-image/feature2.png" class="d-block w-100" alt="..." />
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying3" data-bs-slide="prev">
                                    {/* <span class="carousel-control-prev-icon" aria-hidden="true"></span> */}
                                    <Icon.ChevronLeft className='text-dark' />
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying3" data-bs-slide="next">
                                    {/* <span class="carousel-control-next-icon" aria-hidden="true"></span> */}
                                    <Icon.ChevronRight className='text-dark' />
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 features-details-image">
                            <div id="carouselExampleAutoplaying4" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <img src="/images/features-details-image/feature1.png" class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="/images/features-details-image/feature2.png" class="d-block w-100" alt="..." />
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying4" data-bs-slide="prev">
                                    {/* <span class="carousel-control-prev-icon" aria-hidden="true"></span> */}
                                    <Icon.ChevronLeft className='text-dark' />
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying4" data-bs-slide="next">
                                    {/* <span class="carousel-control-next-icon" aria-hidden="true"></span> */}
                                    <Icon.ChevronRight className='text-dark' />
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>

                        <div className="col-lg-6 features-details">
                            <div className="features-details-desc">
                                <h3>Calls and Recording Synced</h3>
                                <p className='fs-6'><Icon.CheckCircle className='text-primary'/>&nbsp; &nbsp;Use the "Tap to Sync" feature to easily bring together all call records into the CRM system for complete and organized data integration.</p>
                                <p className='fs-6'><Icon.CheckCircle className='text-primary'/>&nbsp; &nbsp;Automatically record every business call for clear and accurate records, promoting transparency and accountability in your organization.</p>
                                <p className='fs-6'><Icon.CheckCircle className='text-primary'/>&nbsp; &nbsp;Keep a close eye on your team's work using detailed recordings. This helps track performance and ensures accountability.</p>
                                <p className='fs-6'><Icon.CheckCircle className='text-primary'/>&nbsp; &nbsp;Track sales activities, set follow-up reminders, and stay updated on overdue and upcoming follow-ups.</p>
                            </div>
                        </div>
                    </div>

                    <div className="separate"></div>

                    <div className="row align-items-center">
                        <div className="col-lg-6 features-details">
                            <div className="features-details-desc">
                                <h3>Notifications</h3>
                                <p>Works Anywhere and on every device." This powerful attribute ensures that users can access and utilize the CRM seamlessly, regardless of their location or the device they are using. Whether in the office, at home, or on the go, the versatility of Betyphon CRM empowers users to stay connected and productive. This flexibility not only enhances convenience but also supports a dynamic and agile work environment, allowing businesses to adapt to various work scenarios effortlessly. With Betyphon's commitment to being accessible from any location and device, users can enjoy the benefits of a fully functional CRM that adapts to their work style and keeps them connected wherever they go.</p>
                            </div>
                        </div>

                        <div className="col-lg-6 features-details-image">
                            <div id="carouselExampleAutoplaying5" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <img src="/images/features-details-image/feature1.png" class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="/images/features-details-image/feature2.png" class="d-block w-100" alt="..." />
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying5" data-bs-slide="prev">
                                    {/* <span class="carousel-control-prev-icon" aria-hidden="true"></span> */}
                                    <Icon.ChevronLeft className='text-dark' />
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying5" data-bs-slide="next">
                                    {/* <span class="carousel-control-next-icon" aria-hidden="true"></span> */}
                                    <Icon.ChevronRight className='text-dark' />
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 features-details-image">
                            <div id="carouselExampleAutoplaying6" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <img src="/images/features-details-image/feature1.png" class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="/images/features-details-image/feature2.png" class="d-block w-100" alt="..." />
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying6" data-bs-slide="prev">
                                    {/* <span class="carousel-control-prev-icon" aria-hidden="true"></span> */}
                                    <Icon.ChevronLeft className='text-dark' />
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying6" data-bs-slide="next">
                                    {/* <span class="carousel-control-next-icon" aria-hidden="true"></span> */}
                                    <Icon.ChevronRight className='text-dark' />
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>

                        <div className="col-lg-6 features-details">
                            <div className="features-details-desc">
                                <h3>Marketplace</h3>
                                <p>Experience seamless lead capture from every conceivable source with our CRM. Our robust system allows you to effortlessly gather valuable information from diverse channels, ensuring that no potential opportunity goes unnoticed. Whether social media platforms, and other trade websites or other sources, our CRM is designed to capture leads from everywhere, consolidating crucial data in one centralized hub. This streamlined approach not only saves time but also enhances the efficiency of lead management, empowering businesses to make informed decisions and foster meaningful customer relationships.</p>
                            </div>
                        </div>
                    </div>

                    <div className="separate"></div>

                    <div className="row align-items-center">
                        <div className="col-lg-6 features-details">
                            <div className="features-details-desc">
                                <h3>AutoDialer</h3>
                                <p>Works Anywhere and on every device." This powerful attribute ensures that users can access and utilize the CRM seamlessly, regardless of their location or the device they are using. Whether in the office, at home, or on the go, the versatility of Betyphon CRM empowers users to stay connected and productive. This flexibility not only enhances convenience but also supports a dynamic and agile work environment, allowing businesses to adapt to various work scenarios effortlessly. With Betyphon's commitment to being accessible from any location and device, users can enjoy the benefits of a fully functional CRM that adapts to their work style and keeps them connected wherever they go.</p>
                            </div>
                        </div>

                        <div className="col-lg-6 features-details-image">
                            <div id="carouselExampleAutoplaying7" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <img src="/images/features-details-image/feature1.png" class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="/images/features-details-image/feature2.png" class="d-block w-100" alt="..." />
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying7" data-bs-slide="prev">
                                    {/* <span class="carousel-control-prev-icon" aria-hidden="true"></span> */}
                                    <Icon.ChevronLeft className='text-dark' />
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying7" data-bs-slide="next">
                                    {/* <span class="carousel-control-next-icon" aria-hidden="true"></span> */}
                                    <Icon.ChevronRight className='text-dark' />
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 features-details-image">
                            <div id="carouselExampleAutoplaying8" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <img src="/images/features-details-image/feature1.png" class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="/images/features-details-image/feature2.png" class="d-block w-100" alt="..." />
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying8" data-bs-slide="prev">
                                    {/* <span class="carousel-control-prev-icon" aria-hidden="true"></span> */}
                                    <Icon.ChevronLeft className='text-dark' />
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying8" data-bs-slide="next">
                                    {/* <span class="carousel-control-next-icon" aria-hidden="true"></span> */}
                                    <Icon.ChevronRight className='text-dark' />
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>

                        <div className="col-lg-6 features-details">
                            <div className="features-details-desc">
                                <h3>Realtime Report</h3>
                                <p>Experience seamless lead capture from every conceivable source with our CRM. Our robust system allows you to effortlessly gather valuable information from diverse channels, ensuring that no potential opportunity goes unnoticed. Whether social media platforms, and other trade websites or other sources, our CRM is designed to capture leads from everywhere, consolidating crucial data in one centralized hub. This streamlined approach not only saves time but also enhances the efficiency of lead management, empowering businesses to make informed decisions and foster meaningful customer relationships.</p>
                            </div>
                        </div>
                    </div>

                    <div className="separate"></div>

                    <div className="row align-items-center">
                        <div className="col-lg-6 features-details">
                            <div className="features-details-desc">
                                <h3>System Logs</h3>
                                <p>Works Anywhere and on every device." This powerful attribute ensures that users can access and utilize the CRM seamlessly, regardless of their location or the device they are using. Whether in the office, at home, or on the go, the versatility of Betyphon CRM empowers users to stay connected and productive. This flexibility not only enhances convenience but also supports a dynamic and agile work environment, allowing businesses to adapt to various work scenarios effortlessly. With Betyphon's commitment to being accessible from any location and device, users can enjoy the benefits of a fully functional CRM that adapts to their work style and keeps them connected wherever they go.</p>
                            </div>
                        </div>

                        <div className="col-lg-6 features-details-image">
                            <div id="carouselExampleAutoplaying9" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <img src="/images/features-details-image/feature1.png" class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="/images/features-details-image/feature2.png" class="d-block w-100" alt="..." />
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying9" data-bs-slide="prev">
                                    {/* <span class="carousel-control-prev-icon" aria-hidden="true"></span> */}
                                    <Icon.ChevronLeft className='text-dark' />
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying9" data-bs-slide="next">
                                    {/* <span class="carousel-control-next-icon" aria-hidden="true"></span> */}
                                    <Icon.ChevronRight className='text-dark' />
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 features-details-image">
                            <div id="carouselExampleAutoplaying10" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <img src="/images/features-details-image/feature1.png" class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="/images/features-details-image/feature2.png" class="d-block w-100" alt="..." />
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying10" data-bs-slide="prev">
                                    {/* <span class="carousel-control-prev-icon" aria-hidden="true"></span> */}
                                    <Icon.ChevronLeft className='text-dark' />
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying10" data-bs-slide="next">
                                    {/* <span class="carousel-control-next-icon" aria-hidden="true"></span> */}
                                    <Icon.ChevronRight className='text-dark' />
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>

                        <div className="col-lg-6 features-details">
                            <div className="features-details-desc">
                                <h3>Number Masking</h3>
                                <p>Experience seamless lead capture from every conceivable source with our CRM. Our robust system allows you to effortlessly gather valuable information from diverse channels, ensuring that no potential opportunity goes unnoticed. Whether social media platforms, and other trade websites or other sources, our CRM is designed to capture leads from everywhere, consolidating crucial data in one centralized hub. This streamlined approach not only saves time but also enhances the efficiency of lead management, empowering businesses to make informed decisions and foster meaningful customer relationships.</p>
                            </div>
                        </div>
                    </div>

                    <div className="separate"></div>

                    <div className="row align-items-center">
                        <div className="col-lg-6 features-details">
                            <div className="features-details-desc">
                                <h3>Tag Analysis</h3>
                                <p>Works Anywhere and on every device." This powerful attribute ensures that users can access and utilize the CRM seamlessly, regardless of their location or the device they are using. Whether in the office, at home, or on the go, the versatility of Betyphon CRM empowers users to stay connected and productive. This flexibility not only enhances convenience but also supports a dynamic and agile work environment, allowing businesses to adapt to various work scenarios effortlessly. With Betyphon's commitment to being accessible from any location and device, users can enjoy the benefits of a fully functional CRM that adapts to their work style and keeps them connected wherever they go.</p>
                            </div>
                        </div>

                        <div className="col-lg-6 features-details-image">
                            <div id="carouselExampleAutoplaying11" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <img src="/images/features-details-image/feature1.png" class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="/images/features-details-image/feature2.png" class="d-block w-100" alt="..." />
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying11" data-bs-slide="prev">
                                    {/* <span class="carousel-control-prev-icon" aria-hidden="true"></span> */}
                                    <Icon.ChevronLeft className='text-dark' />
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying11" data-bs-slide="next">
                                    {/* <span class="carousel-control-next-icon" aria-hidden="true"></span> */}
                                    <Icon.ChevronRight className='text-dark' />
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 features-details-image">
                            <div id="carouselExampleAutoplaying12" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <img src="/images/features-details-image/feature1.png" class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="/images/features-details-image/feature2.png" class="d-block w-100" alt="..." />
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying12" data-bs-slide="prev">
                                    {/* <span class="carousel-control-prev-icon" aria-hidden="true"></span> */}
                                    <Icon.ChevronLeft className='text-dark' />
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying12" data-bs-slide="next">
                                    {/* <span class="carousel-control-next-icon" aria-hidden="true"></span> */}
                                    <Icon.ChevronRight className='text-dark' />
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>

                        <div className="col-lg-6 features-details">
                            <div className="features-details-desc">
                                <h3>Customer Support</h3>
                                <p className='fs-5'>Connect Customer support team instantly for effective product use.</p>
                                <p className='fs-6'><Icon.CheckCircle className='text-primary'/>&nbsp; &nbsp;Dedicated SPOC person with Whatsapp Group and proven Implementation process.</p>
                                <p className='fs-6'><Icon.CheckCircle className='text-primary'/>&nbsp; &nbsp;Knowledgebase for all query aspect with Demo video.</p>
                                <p className='fs-6'><Icon.CheckCircle className='text-primary'/>&nbsp; &nbsp;Simple Ticket System with Calendar option to book query and solution as per convenient time.</p>
                                <p className='fs-6'><Icon.CheckCircle className='text-primary'/>&nbsp; &nbsp;Call on 8750- 829-829 or write us <a href="mailto:support@techmet.in">support@techmet.in</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FeatureDetails;