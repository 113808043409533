import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import FeatureDetails from './pages/Feature-details';
import Contact from './pages/Contact';
import Navbar from './components/_App/Navbar';
import Footer from './components/_App/Footer';
import ITStartup from './pages/It-startup';
import Pricing from './pages/Pricing';
import Blog from './pages/Blog';
import BlogDetails from './pages/Blog-details';
import PopUp from './pages/Pop-up';
import React, { useEffect, useState } from 'react';
import Privacy_policy from './pages/Privacy_policy';
import Integration from './pages/Integration';
import ScrollToTop from './pages/ScrollToTop';

function App() {
  const [showPopUp, setShowPopUp] = useState(false);

  const handleClose = () => setShowPopUp(false);

  useEffect(() => {
    // Set a timeout to show the pop-up after a delay (e.g., 2 seconds)
    const timeoutId = setTimeout(() => {
      setShowPopUp(true);
    }, 2000);

    // Clear the timeout to prevent showing the pop-up if the component unmounts
    return () => clearTimeout(timeoutId);
  }, []);
  
  return (
    <div>
    <BrowserRouter>
        <ScrollToTop/>
      <Navbar/>
        <Routes>
          <Route path='/' element={<ITStartup/>} />
          <Route path='/pricing/' element={<Pricing/>} />
          <Route path='/feature-details/' element={<FeatureDetails/>} />
          <Route path='/integration/' element={<Integration/>} />
          <Route path='/blog/' element={<Blog/>} />
          <Route path='/blog-details/' element={<BlogDetails/>} />
          <Route path='/contact/' element={<Contact/>} />
          <Route path='/privacy.html' element={<Privacy_policy/>} />
          <Route path='/privacy/' element={<Privacy_policy/>} />
        </Routes>
      <Footer/>
    </BrowserRouter>
    <PopUp show={showPopUp} handleClose={handleClose} />
    <a href="https://wa.me/+919871045375" className="float bounce" target="_blank">
      <i className="fa fa-whatsapp my-float"></i>
    </a>
    </div>
  );
}

export default App;
