import React from 'react';
import * as Icon from 'react-feather';

const Features = () => {
    return (
        <>
            <div className="boxes-area mt-1">
                <div className="container mt-5 pt-5">
                <div className="section-title">
                                <h2>Why Businesses Trust BeTyphon?</h2>
                                <div className="bar"></div>
                            </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="single-box">
                                <div className="icon">
                                    <Icon.Zap />
                                </div>

                                <h3>
                                    {/* <Link href="#"> */}
                                        Improves Telecalling Productivity
                                    {/* </Link> */}
                                </h3>

                                <p>Find ways to help callers do their tasks better by looking at current phone information, without guessing anything.</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <div className="single-box bg-f78acb">
                                <div className="icon">
                                    <Icon.Clock />
                                </div>

                                <h3>
                                    {/* <Link href="#"> */}
                                        Reduces Non-productive Time
                                    {/* </Link> */}
                                </h3>

                                <p>Find efficiency gaps to minimize nonproductive time by visualizing targeted, non-targeted, connected, or disconnected calls daily.</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <div className="single-box bg-c679e3">
                                <div className="icon">
                                    <Icon.Users />
                                </div>

                                <h3>
                                    {/* <Link href="#"> */}
                                        Efficient Performance Evaluation
                                    {/* </Link> */}
                                </h3>

                                <p>Get real-time performance analytics of each team member and save time on creating manual performance reports.</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <div className="single-box bg-eb6b3d">
                                <div className="icon">
                                    <Icon.Settings />
                                </div>

                                <h3>
                                    {/* <Link href="#"> */}
                                        Automates your Operations
                                    {/* </Link> */}
                                </h3>

                                <p>Automate tasks that repeat often, such as keeping contacts up to date, handling leads, making reports, and other similar activities, and more through third-party integrations.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Features;
