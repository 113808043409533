import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import './Owl_Carousel.css';

const Owl_Carousel = () => {
  const options = {
    items: 3,
    loop: true,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 2000,
    nav: false,
    dots: false,
    margin: 0,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  return (
    // <OwlCarousel
    //   className="owl-theme"
    //   {...options}
    // >
    //   {items}
    // </OwlCarousel>
    <OwlCarousel className='owl-theme' {...options}>
      <div key={1} className="ps-1">
        <img src='/images/owl_carousel/bharath.png'></img>
      </div>,
      <div key={2} className="ps-1">
        <img src='/images/owl_carousel/glocal.png'></img>
      </div>,
      <div key={2} className="ps-1">
        <img src='/images/owl_carousel/hitech.png'></img>
      </div>,
      <div key={4} className="ps-1">
        <img src='/images/owl_carousel/livevaastu.png'></img>
      </div>,
      <div key={5} className="ps-1">
        <img src='/images/owl_carousel/loanmoney.png'></img>
      </div>,
      <div key={6} className="ps-1">
        <img src='/images/owl_carousel/apex.png'></img>
      </div>,
      <div key={7} className="ps-1">
        <img src='/images/owl_carousel/mayuri1.png'></img>
      </div>,
      {/* <div key={8} className="ps-1">
        <img src='/images/owl_carousel/dr_sanjeev.png'></img>
      </div>, */}
    </OwlCarousel>
  );
};

export default Owl_Carousel;
