import React, { useEffect, useState } from 'react'
import { Link, useLocation } from "react-router-dom";
import Aos from 'aos';
import 'aos/dist/aos.css';
// import Shuffle from 'react-shuffle';

const Partner = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);

    const [images, setImages] = useState([
        '/images/partner-img/99acres.png',
        '/images/partner-img/facebook.png',
        '/images/partner-img/indiamart.jpg',
        '/images/partner-img/interakt.png',
        '/images/partner-img/justdial.jpg',
        '/images/partner-img/whatsapp.jpg',
        '/images/partner-img/shiksha.jpg',
        '/images/partner-img/webhooks.png',
        '/images/partner-img/tradeindia.jpg',
        '/images/partner-img/exportindia.png',
        '/images/partner-img/collagedunia.jpg',
        '/images/partner-img/sulekha.jpg',
        '/images/partner-img/magicbrick.jpg',
        '/images/partner-img/hubspot.png',
        '/images/partner-img/wati.png',
    ]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            // Shuffle the images
            const shuffledImages = [...images];
            for (let i = shuffledImages.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [shuffledImages[i], shuffledImages[j]] = [shuffledImages[j], shuffledImages[i]];
            }
            setImages(shuffledImages);
        }, 2000); // Adjust the interval as needed

        return () => clearInterval(intervalId);
    }, [images]);

    return (
        <>
            <div className="ready-to-talk">
                <div className="container">
                    <h3>Our Integrations</h3>
                    <p>Get all the custom integrations you need based on your business needs. Connect with customer on-time</p>

                    <Link to="/contact" className="btn btn-primary">
                        Contact Us
                    </Link>

                    <span>
                        <Link to="https://web.betyphon.in/auth/register-page">
                            <p>3 days Free Trial</p>
                        </Link>
                    </span>
                </div>
            </div>

            <div className="partner-area partner-section">
                <div className="container">
                    {/* <h5 className='text-center'>More that 5000+ businesses and organizations use BeTyphon CRM</h5> */}
                    {/* <div className="partner-inner">
                        <div className="row justify-content-center">
                            <div className="col-lg-2 col-md-3 col-6">
                                <a to="#" target="_balnk">
                                    <img src="/images/partner-img/collegedunia.png" alt="" />
                                    <img src="/images/partner-img/collegedunia.png" alt="partner" />
                                </a> 
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a to="#" target="_balnk">
                                    <img src="/images/partner-img/ei-logo.png" alt="" />
                                    <img src="/images/partner-img/ei-logo.png" alt="partner" />
                                </a>
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a to="#" target="_balnk">
                                    <img src="/images/partner-img/fbImage.png" alt="partner" />
                                    <img src="/images/partner-img/fbImage.png" alt="partner" />
                                </a>
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a to="#" target="_balnk">
                                    <img src="/images/partner-img/indiamart.png" alt="partner" />
                                    <img src="/images/partner-img/indiamart.png" alt="partner" />
                                </a> 
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a to="#" target="_balnk">
                                    <img src="/images/partner-img/Interakt.webp" alt="partner" />
                                    <img src="/images/partner-img/Interakt.webp" alt="partner" />
                                </a> 
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a to="#" target="_balnk">
                                    <img src="/images/partner-img/justdial-logo.png" alt="partner" />
                                    <img src="/images/partner-img/justdial-logo.png" alt="partner" />
                                </a>
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a to="#" target="_balnk">
                                    <img src="/images/partner-img/shiksha.png" alt="partner" />
                                    <img src="/images/partner-img/shiksha.png" alt="partner" />
                                </a>
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a to="#" target="_balnk">
                                    <img src="/images/partner-img/tradeindialogo.png" alt="partner" />
                                    <img src="/images/partner-img/tradeindialogo.png" alt="partner" />
                                </a>
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a to="#" target="_balnk">
                                    <img src="/images/partner-img/webhooks.png" alt="partner" />
                                    <img src="/images/partner-img/webhooks.png" alt="partner" />
                                </a>
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a to="#" target="_balnk">
                                    <img src="/images/partner-img/99acres.jpeg" alt="partner" />
                                    <img src="/images/partner-img/99acres.jpeg" alt="partner" />
                                </a>
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a to="#" target="_balnk">
                                    <img src="/images/partner-img/callingsetup.png" alt="partner" />
                                    <img src="/images/partner-img/callingsetup.png" alt="partner" />
                                </a>
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a to="#" target="_balnk">
                                    <img src="/images/partner-img/whatsIcon.gif" alt="partner" />
                                    <img src="/images/partner-img/whatsIcon.gif" alt="partner" />
                                </a>
                            </div>
                        </div>
                    </div> */}
                    <div className="partner-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 mt-1">
                                    <table>
                                        <tr>
                                            {/* <td></td> */}
                                            {images.slice(10).map((image, index) => (
                                                <td key={index} className='py-2' data-aos="zoom-in">
                                                    <img src={image} width="55%" alt={`partner-${index}`} />
                                                </td>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td></td>
                                            {images.slice(0, 4).map((image, index) => (
                                                <td key={index} className='py-2' data-aos="zoom-in">
                                                    <img src={image} width="55%" alt={`partner-${index}`} />
                                                </td>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            {images.slice(4, 7).map((image, index) => (
                                                <td key={index} className='py-2' data-aos="zoom-in">
                                                    <img src={image} width="55%" alt={`partner-${index}`} />
                                                </td>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            {images.slice(7, 9).map((image, index) => (
                                                <td key={index} className='py-2' data-aos="zoom-in">
                                                    <img src={image} width="55%" alt={`partner-${index}`} />
                                                </td>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            {images.slice(9, 10).map((image, index) => (
                                                <td key={index} className='py-2' data-aos="zoom-in">
                                                    <img src={image} width="55%" alt={`partner-${index}`} />
                                                </td>
                                            ))}
                                        </tr>
                                    </table>
                                </div>
                                <div className="col-md-6">
                                    <div className="row mt-4">
                                        <h4 className='mb-4'><b>Benefit from a real-time, no code integration process. Get custom integrations & effortlessly connect to your Betyphon account</b></h4>
                                        <ul className='ms-4'>
                                            <li><p className='my-1 text-dark fs-6'>Integrate your favourite tools to get the enquiry on real-time</p></li>
                                            <li><p className='my-1 text-dark fs-6'>Auto-Sync Leads with dynamic auto-distribution to team</p></li>
                                            <li><p className='my-1 text-dark fs-6'>System Logs feature with Repeated highlights</p></li>
                                            <li><p className='my-1 text-dark fs-6'>200+ Integration with Customize Open-Source-API</p></li>
                                            <li><p className='my-1 text-dark fs-6'>Easy setup in minute, Scale as you grow</p></li>
                                        </ul>
                                    </div>
                                            <Link to="/integration/" className="btn btn-primary py-2 px-3 m-1">Know More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Partner;  